import { fetchFromNewApi } from '~/helpers/api.ts'
import { useNewSettingsStore } from '~/store/newSettingsStore'

const getSettingsStore = () => {
  return useNewSettingsStore()
}

export async function ExchangeRate(amount, roundup = false) {
  try {
    const { currentCurrency } = getSettingsStore()
    const res = await fetchFromNewApi(`/api/v1/accounting/exchange-rate/gbp`, {
      params: {
        CurrencyId: currentCurrency.Id,
      },
    })
    return res
  } catch (e) {
    // throw error
    console.error(e)
  }
}

export async function royalMailSubscriptions(orderId, subscription) {
  try {
    const res = await fetchFromNewApi('/WebService.asmx/SetUpRoyalMailNotifications', {
      method: 'POST',
      body: {
        orderId,
        smsNotification: subscription === 'sms' || subscription === 'both',
        emailNotification: subscription === 'email' || subscription === 'both',
      },
    })

    return res.d
  } catch (e) {
    // throw error
    console.error(e)
  }
}
